import { useState, useEffect } from 'react';
import { Input, Alert } from 'antd';
import { Facebook, Instagram, TwitterX, Tiktok, Linkedin } from 'react-bootstrap-icons';
import Data from '../data/Data';
import { ExclamationCircle } from 'react-bootstrap-icons';
import EitDigital from '../assets/eit-digital.png';
import KhStartIt from '../assets/kh-start-it.png';
import F6s from '../assets/f6s-award.png';
import Csaba from '../assets/csaba.png';
import Levi from '../assets/levi.png';
import Zsombor from '../assets/zsombor.png';
import MastercardLogo from '../assets/payment/mastercard.png';
import VisaLogo from '../assets/payment/visa.png';
// import ArticleCard from './ArticleCard';
import LoadingGif from '../assets/loading.gif';

import Header from './Header';
import Video from './Video';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function Home() {
    const actualYear = new Date().getFullYear();

    const [inputEmail, setInputEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [isEmailLoading, setIsEmailLoading] = useState(false);
    // const [articles, setArticles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                // const data = await Data.getNews();

                // const articlePromises = data.map(async (url) => {
                //     try {
                //         console.log(`Fetching article from ${url}`);
                //         const articleResponse = await fetch(`https://cors-anywhere.herokuapp.com/${url}`);
                //         if (!articleResponse.ok) {
                //             throw new Error(`Failed to fetch article from ${url}`);
                //         }
                //         const htmlString = await articleResponse.text();

                //         const parser = new DOMParser();
                //         const doc = parser.parseFromString(htmlString, 'text/html');

                //         const title = doc.querySelector('meta[property="og:title"]')?.getAttribute('content') || 'No title';
                //         const description = doc.querySelector('meta[property="og:description"]')?.getAttribute('content') || 'No description';
                //         const publishDate = doc.querySelector('meta[property="article:published_time"]')?.getAttribute('content') || new Date().toISOString();
                //         const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || 'https://via.placeholder.com/150';

                //         return { title, description, publishDate, image, url };
                //     } catch (articleError) {
                //         console.error(`Error fetching article from ${url}:`, articleError);
                //         return null;
                //     }
                // });

                // const articles = (await Promise.all(articlePromises)).filter(article => article !== null);
                // setArticles([]);
            } catch (apiError) {
                console.error("Error fetching articles:", apiError);
            } finally {
                hideLoading(false);
            }
        };

        fetchArticles();
    }, []);
    
    async function handleSubscribe() {
        try {
            await Data.subscribe(inputEmail);

            setEmailSuccess(true);
            setTimeout(() => {
                setInputEmail('');
                setEmailSuccess(false);
                setIsEmailLoading(false);
            }, 1500);
        } catch {
            setEmailError('Incorrect e-mail address');
        }
    }

    function changeEmailInput(e) {
        setEmailError(null);
        setInputEmail(e.target.value)
    }

    // const chunkArticles = (articles, chunkSize) => {
    //     const result = [];
    //     for (let i = 0; i < articles.length; i += chunkSize) {
    //         result.push(articles.slice(i, i + chunkSize));
    //     }
    //     return result;
    // };

    function hideLoading() {
        gsap.to('.loading', {
            opacity: 0,
            duration: 0.3,
            ease: "power1.inOut"
        });

        setTimeout(() => {
            setIsLoading(false)

            gsap.fromTo('.hero-h1', {
                opacity: 0,
                y: '20px'
            }, {
                opacity: 1,
                y: 0,
                duration: 0.8,
                ease: "power1.inOut"
            });
    
            setTimeout(() => {
                gsap.fromTo('.hero-h2', {
                    opacity: 0,
                    y: '20px'
                }, {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    ease: "power1.inOut"
                });
            }, '400');
        }, '300');
    }

    // const articleChunks = chunkArticles(articles, 3);
    // const mobileArticleChunks = chunkArticles(articles, 1);

    return (
        <div className="Home">
            {isLoading && (
                <div className='loading d-flex justify-content-center align-items-center'>
                    <img src={LoadingGif} alt='Loading...' />
                </div>
            )}
            <Header />
            <section id="landing" className="landing container-fluid">
                <div className="row">
                    <div className='col-lg-12 text-center'>
                        <h1 className='mb-5 hero-h1'>Say your final goodbye to <i>passwords</i>,<br />hello to seamless security</h1>
                        <h2 className='hero-h2'>Experience passwordless, biometric login-boosting security<br />and simplifying access for your business.</h2>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <div className='col-lg-8 d-flex justify-content-center'>
                            <Video />
                        </div>
                    </div>
                </div>
            </section>

            <section id='about' className='container d-flex justify-content-center'>
                <div className='col-md-9 mx-3'>
                    <p>
                        Ubives is a pioneering user management solution that revolutionizes online identification and data management. It's passwordless technology simplifies login and minimizes administrative tasks and security risks. With flexible integration and compatibility with other services and systems, it offers significant cost savings compared to traditional identification methods for businesses. Its mission is to promote online security and convenience, providing innovative solutions in the field of digital identity management.
                    </p>
                </div>
            </section>

            <section id='our-team' className='container-fluid d-flex align-items-center'>
                <div className='container'>
                    <h2>Who we are</h2>
                    <div className='row'>
                        <div className='col-lg-4 text-center'>
                            <img src={Csaba} alt='portrait' className='portrait' />
                            <h3>Csaba Nyírő</h3>
                            <h4>CEO</h4>
                            <p>Currently a student at Óbuda University. Worked at various companies and skilled at cybersecurity, marketing and sales.</p>
                        </div>
                        <div className='col-lg-4 text-center'>
                            <img src={Levi} alt='portrait' className='portrait' />
                            <h3>Levente Nyírő</h3>
                            <h4>CTO</h4>
                            <p>Currently a student at ELTE. Working as a software developer and skilled at AI and UX/UI design.</p>
                        </div>
                        <div className='col-lg-4 text-center'>
                            <img src={Zsombor} alt='portrait' className='portrait' />
                            <h3>Zsombor Németh</h3>
                            <h4>CISO</h4>
                            <p>Currently a student at BME. Working as a software developer and skilled at cybersecurity.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* TODO: fix news-bug */}
            {/* <section id="news" className='container px-5'>
                <h2 className='text-end'>News</h2>
                <div className='d-none d-lg-block'>
                    <Carousel arrows>
                        {articleChunks.map((chunk, index) => (
                            <div key={index} className='carousel-slide'>
                                <div className='carousel-slide-content'>
                                    {chunk.map((article, idx) => (
                                        <ArticleCard key={idx} {...article} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className='d-lg-none'>
                    <Carousel arrows dots>
                        {mobileArticleChunks.map((chunk, index) => (
                            <div key={index} className='carousel-slide'>
                                <div className='carousel-slide-content'>
                                    {chunk.map((article, idx) => (
                                        <ArticleCard key={idx} {...article} />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section> */}

            <section id='subscribe' className='container-fluid'>
                <div className='row'>
                    <div className='col-6 bg-subscribe d-none d-md-block'></div>
                    <div className='col-12 col-md-6 pt-3'>
                        <h2>Contact<br />our<br />team</h2>

                        <p className='mt-3'>Our application isn't ready yet, but exciting developments are coming! Subscribe to our newsletter for updates.</p>

                        <div className='mx-5 mt-5'>
                            <label>E-mail address</label>
                            <Input size="large" placeholder="example@example.com" onChange={changeEmailInput} status={emailError !== null ? 'error' : ''} suffix={emailError ? <ExclamationCircle /> : <span />} disabled={isEmailLoading} />

                            {emailSuccess && <Alert message="Successful subscription!" type="success" className="mt-3" />}

                            <div className='d-flex justify-content-end mt-3'>
                                <button key="submit" type="primary" onClick={handleSubscribe} disabled={isEmailLoading} className='btn-ubives'>
                                    Subscribe
                                </button>
                            </div>
                        </div>

                        <p className='mt-5 me-5'>If you want to explore investment opportunities with us, write an email to <a href='mailto:invest@ubives.com'>invest@ubives.com</a>.</p>
                    </div>
                </div>
            </section>

            <section id='awards' className='container-fluid my-5'>
                <div className='container'>
                    <h2>Awards</h2>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-6 d-flex justify-content-center my-3'>
                        <img src={F6s} className='f6s' alt='f6s - 5th Cyber Security Startup of July 2024' onClick={() => window.open('https://www.f6s.com/ubives')} />
                    </div>
                </div>
            </section>

            <section id='our-partners' className='container-fluid my-5'>
                <div className='container'>
                    <h2>Supporters</h2>
                </div>
                <div className='row mt-5'>
                    <div className='col-md-6 d-flex justify-content-center my-3'>
                        <img src={EitDigital} className='eit-digital' alt='eit-digital' />
                    </div>
                    <div className='col-md-6 d-flex justify-content-center my-3'>
                        <img src={KhStartIt} className='kh-start-it' alt='kh-start-it' />
                    </div>
                </div>
            </section>

            <footer className="container-fluid">
                <div className="row pb-5 mx-5">
                    <div className="col-md-4 order-last order-md-first mt-5">
                        <div className='d-flex social-links mb-4'>
                            <Facebook onClick={() => window.open('https://www.facebook.com/profile.php?id=61560271872685')} />
                            <Instagram onClick={() => window.open('https://www.instagram.com/ubives_official/')} />
                            <TwitterX onClick={() => window.open('https://x.com/ubives_official')} />
                            <Tiktok onClick={() => window.open('https://www.tiktok.com/@ubives_official')} />
                            <Linkedin onClick={() => window.open('https://www.linkedin.com/company/ubives')} />
                        </div>
                        <div className='d-flex mb-4'>
                            <div className='payment'>
                                <img src={MastercardLogo} alt='mastercard' />
                            </div>
                            <div className='payment'>
                                <img src={VisaLogo} alt='visa' />
                            </div>
                        </div>
                        <p className='mt-1'><a href='mailto:invest@ubives.com'>marcel@ubives.com</a><br /><b>Ubives Kft.</b> - {actualYear}</p>
                        <p><b>VAT Number:</b> HU32624034<br /><b>Company Registration Number:</b> 01-09-434045<br /><b>Fiscal Identity Number:</b> 32624034-2-42<br />1145 Budapest, Tallér str 15.</p>
                    </div>
                    <div className="col-md-4 links mt-5">
                        <h2>Useful links</h2>
                        <ul className="mt-3">
                            <li>
                                <a href="#about">About Us</a>
                            </li>
                            <li>
                                <a href="/">Career</a>
                            </li>
                            <li>
                                <a href="https://app.ubives.com">Partner Portal</a>
                            </li>
                            <li>
                                <a href="#news">Press</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 links mt-5">
                        <h2>Documents</h2>
                        <ul className="mt-3">
                            <li>
                                <a href="/" target='_blank'>GDPR</a>
                            </li>
                            <li>
                                <a href="/" target='_blank'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href="https://docs.ubives.com">Developer Documentation</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Home;
